import React, { useState } from 'react'

import { SlideShowIndicatorWrapper } from './styled'
import WWp from '@vector/WW-pinched-icon.svg'
// import WWpUrl, { ReactComponent as WWp } from '@vector/WW-pinched-icon.svg'

const SlideShowIndicator = props => {
  return (
    <SlideShowIndicatorWrapper { ...props }>
      <img src={ WWp }
        data-parent-class={ props.class }
      />
    </SlideShowIndicatorWrapper>
  )
}

export default SlideShowIndicator
