import React from 'react'

import Page from '@layout'
import SlideShow from '@components/slideshow'
import HypeAndSocial from '@components/hype-n-social'
import BannerVideo from '@components/banner'
import WWidgets from '@widgets'
import Drool from '@components/drool'

const IndexPage = () => {
  return (
    <Page id='land'
      headerVisible
      preFooterVisible
      footerVisible
    >
      <section id='splash'>
        <SlideShow />
        <HypeAndSocial />
        <BannerVideo id='bandcamp-2021-video' />
      </section>
      <section id='wwidgets'>
        <WWidgets />
      </section>
      <section id='drool'>
        <Drool />
      </section>
    </Page>
  )
}

export default IndexPage
