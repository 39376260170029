import React from 'react'

import { EmH1, EmH2, BrIfLarge } from '@styles'
import { ShopLogoBubble, WWWant } from './styled'
import Redbubble from '@widgets/redbubble'
import BandcampLogoAss from '@raster/bandcamp-logo.png'
import RedbubbleLogoAss from '@vector/redbubble-logo.svg'

const Drool = ({ w }) => {
  return (
    <>
      <EmH1>GET OUR ARTWORK printed on WHATEVER THE F⨃¢K YOU WANT!</EmH1>
      <EmH1>
        <WWWant href='https://want.wrongwindows.rocks'>WANT.WRONGWINDOWS.ROCKS</WWWant>
      </EmH1>
      <EmH1 className='taller'>DROOL OVER EVEN MORE PRODUCTS <BrIfLarge />@ our
        { ' ' }
        <a className='clearUnfix'
          href='http://redbubble.wrongwindows.rocks'
          target='_blank'
        >
          <ShopLogoBubble src={ RedbubbleLogoAss }
            height='52'
            className='redbubble'
          />
        </a> &amp; <a className='clearUnfix'
          href='https://wrongwindows.bandcamp.com'
          target='_blank'
        >
          <ShopLogoBubble src={ BandcampLogoAss }
            height='42'
            className='bandcamp'
          />
        </a> SUB-SHOPS!
      </EmH1>
      <Redbubble w={ w } />
      <EmH2>Please note that not all products are available at all 3 online shops&hellip; If you don't see what you want offered on one shop, be sure to check the other two!</EmH2>
    </>
  )
}

export default Drool
