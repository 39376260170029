import styled, { css } from 'styled-components'

import { emboldenedTitle, media } from '@styles'

export const HypeAndSocialWrapper = styled.div`
  border-top: solid 5px white;
  height: 72vw;
  margin: -1px 0 0 0;
  padding-top: 4px;
  position: relative;
  transform-origin: 0 0;
  width: 100vw;
  ${media.minWidth.mobile`
    height: 30vw;
    padding-top: 0;
  `}
  ${media.minWidth.tablet`
    height: auto;
  `}
  ${media.minWidth.large`
    height: calc(10.2352941vw + 6px);
  `}
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: black;
    top: -3px;
    left: 0;
    z-index: 2;
  }
`

export const BandNameBlock = styled.div`
  ${emboldenedTitle({ sizeUnits: 'vw', fontSize: 8.8, shadowSize: 0.20952381 })}
  position: relative;
  display: block;
  height: 10.2352941vw;
  left: 0;
  top: 0.94117647vw;
  white-space: nowrap;
  width: 100vw;
  ${media.minWidth.large`
    position: absolute;
    width: 54.5882353vw;
    ${emboldenedTitle({ sizeUnits: 'vw', fontSize: 4.94117647, shadowSize: 0.11764706 })}
  `}
`

export const HypeAndSocialBlock = styled.div`
  position: relative;
  margin: 6px 0 0 0;
  top: 0;
  transform-origin: 0 0;
  width: 100vw;
  display: flex;
  justify-content: space-around;
  align-content: stretch;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  ${media.minWidth.mobile`
    height: 11.7647059vw;
  `}
  ${media.minWidth.tablet`
    flex-direction: row;
  `}
  ${media.minWidth.large`
    position: absolute;
    justify-content: space-between;
    align-items: flex-start;
    left: 54.5882353vw;
    width: 45.4117647vw;
  `}`

export const BandcampTextLinkBlock = styled.div`
  display: block;
  font-size: 4vw;
  height: 13vw;
  text-align: center;
  margin-top: 4vw;
  left: 0.32vw;
  width: 100vw;
  transform: scaleY(1.5);
  letter-spacing: -0.11764706vw;
  ${media.minWidth.mobile`
    font-size: 2.42vw;
    height: 10.2352941vw;
    margin-top: 2.76vw;
  `}
  ${media.minWidth.tablet`
    left: -0.64vw;
    width: auto;
    margin-top: 1.24vw;
    transform: none;
    letter-spacing: auto;
  `}
  ${media.minWidth.large`
    max-width: 58%;
    font-size: 1.64705882vw;
    width: 25.8823529vw;
    margin-top: 0.405vw;
  `}
`

export const SocialLinksBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  transform-origin: 50% 0%;
  transform: scale(2.24);
  width: 42vw;
  margin-bottom: 1vw;
  ${media.minWidth.mobile`
    flex-wrap: nowrap;
  `}
  ${media.minWidth.tablet`
    margin: -6px 0 0 3vw;
    width: auto;
    transform-origin: 50% 50%;
    transform: scale(1.64);
  `}
  ${media.minWidth.large`
    flex-wrap: wrap;
    margin: 0;
    right: 0.23vw;
    width: 37%;
    transform: none;
  `}
  a {
    background-size: contain;
    cursor: pointer;
    display: inline-block;
    height: 7.55vw;
    margin: 0.42vw;
    padding: 0;
    position: relative;
    width: 7.55vw;
    ${media.minWidth.mobile`
      height: 2.86vw;
      margin: 0.23vw;
      width: 2.86vw;
    `}
    img {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      background-size: contain;
      transition: opacity 0.25s ease-in-out;
      &.O {
        opacity: 0;
      }
    }
    &:hover img.O {
      opacity: 1;
    }
  }
`
