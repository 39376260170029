import styled from 'styled-components'
import 'react-slideshow-image/dist/styles.css'

// import { media } from '@styles'
import { setRgba } from '@styles'
import animate from '@keyframes'
import { shapedAura } from '@styles'
import TPBL_BWzigzags from '@raster/WW_Black_Lodge_Tiled_FBx2.gif'

export const SlideShowWrapper = styled.div.attrs(props => ({
  className: props.className || 'clearUnfix',
  'aria-label': props['aria-label'] || 'Slide Show',
}))`
  top: 0;
  position: relative;
  display: block;
  width: 100vw;
  padding-top: 37.058823%;
  transform-origin: 0 0;
  overflow: hidden;
  margin-top: -1px;
  background-color: black;
  background-image: url('${TPBL_BWzigzags}');
  background-size: 100%;
  > div {
    background-image: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    ${animate.fadeIn({ duration: 1.25, delay: 0.5 })}
  }
  ul.indicators {
    position: absolute;
    margin: 0;
    z-index: 42000;
    height: 1.41176471vw;
    width: 100%;
    display: flex;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    opacity: 0;
    transform: translateY(0);
    transition: all 0.15s ease-in-out;
  }
  &:hover ul.indicators {
    opacity: 1;
    transform: translateY(-2.35294118vw);
  }
  .zoom-wrapper > div > div img {
    cursor: grab;
  }
`

export const SlideShowArrowWrapper = styled.div`
  box-sizing: border-box;
  width: 4.23529412vw;
  margin-${props => props.invert ? 'right' : 'left'}: -4.23529412vw;
  padding-${props => props.invert ? 'left' : 'right'}: 1.41176471vw;
  transform: translateX(${props => props.invert ? '-' : ''}8.47058824vw);
  transition: all 0.15s ease-in-out;
  opacity: 0;
  cursor: pointer;
  img {
    transform: rotate(${props => props.invert ? 90 : 270}deg);
    ${shapedAura(setRgba('siteWhite'), 12)}
    &[spin="1"] {
      ${props => props.invert ? animate.spin({ direction: 'counter', duration: 0.25, startFromDeg: 90 }) : animate.spin({ direction: 'clockwise', duration: 0.25, startFromDeg: 270 })}
    }
  }
  div:hover > div > div > & {
    opacity: 0.72;
    transform: translateX(0);
  }
  &:hover {
    opacity: 0.96 !important;
    transform: scale(1.23) !important;
  }
`

export const SlideShowIndicatorWrapper = styled.div`
  position: relative;
  width: 0.941176vw;
  margin: 0 0.05882353vw;
  opacity: 0.64;
  transition: all 0.05s ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  img {
    position: relative;
    width: 100%;
    ${shapedAura(setRgba('black', false), 4)}
  }
  &.active {
    ${animate.bounceInPlace()}
  }
`

