import React, { useEffect, useState } from 'react'

import { loadJavaScriptInto } from '@utils'
import { WWidgetWrapper, WWidget } from './styled'

const LinkTree = () => {
  const [ linkTreeLoaded, setLinkTreeLoaded ] = useState(false)

  useEffect(() => {
    loadJavaScriptInto('linktree-wwidget', null, 'https://www.2lin.cc/js/embed.js', () => { setLinkTreeLoaded(true) })
  }, []) // RUN ONCE ON LOAD

  return (
    <WWidgetWrapper>
      <WWidget id='linktree-wwidget'>
        <a href='https://www.2lin.cc/plain-WrongWindowsDoY'
          className='music-widget'
          data-width='320px'
          data-height='1400px'
          data-id='grid-widget'
        />
      </WWidget>
    </WWidgetWrapper>
  )
}

export default LinkTree
