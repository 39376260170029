import React, { useState } from 'react'

import { SlideShowArrowWrapper } from './styled'
import WWp from '@vector/WW-pinched-icon.svg'
// import WWpUrl, { ReactComponent as WWp } from '@vector/WW-pinched-icon.svg'

const SlideShowArrow = props => {
  const [
    spin,
    setSpin,
  ] = useState(0)
  return (
    <SlideShowArrowWrapper { ...props }>
      <img src={ WWp }
        onClick={ () => setSpin(1) }
        onAnimationEnd={ () => setSpin(0) }
        spin={ spin }
      />
    </SlideShowArrowWrapper>
  )
}

export default SlideShowArrow
