import React from 'react'

import {
  WWidgetColumnsWrapper,
  WWidgetColumn,
  WWidgetc,
  VectEar,
} from './styled'
import { EmH1 } from '@styles'
import WWHR from '@components/html/ww-hr'
import IloveEAR from '@vector/i-love-ear.svg'
// import IloveEARurl, { ReactComponent as IloveEAR } from '@vector/i-love-ear.svg'
import Spotify from './spotify'
import LinkTree from './linktree'
import SoundCloud from './soundcloud'
import BandCamp from './bandcamp'

const WWidgets = () => {
  return (
    <>
      <EmH1>LISTEN R!GHT N0W with The WR0NG Widgets:</EmH1>
      <WWidgetColumnsWrapper className='clearUnfix'>
        <WWidgetColumn>
          <VectEar src={ IloveEAR }
            className='ear-left'
          />
          <Spotify />
        </WWidgetColumn>
        <WWidgetColumn>
          <LinkTree />
        </WWidgetColumn>
        <WWidgetColumn>
          <VectEar src={ IloveEAR }
            className='ear-right'
          />
          <SoundCloud />
        </WWidgetColumn>
      </WWidgetColumnsWrapper>
      <WWidgetc>
        <BandCamp />
      </WWidgetc>
      <WWHR className='post-wwidgets' />
    </>
  )
}

export default WWidgets
