import React from 'react'

const BandCamp = () => {
  return (
    <iframe scrolling='no'
      src='https://bandcamp.com/band_follow_button_deluxe/2729701547'
    />
  )
}

export default BandCamp
