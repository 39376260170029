import styled from 'styled-components'

import { media } from '@styles'

export const WWWant = styled.a.attrs(props => ({
  href: props.href || '',
  target: props.target || '_self',
}))`
  font-size: 108px;
  word-break: break-all;
`

export const ShopLogoBubble = styled.img.attrs(props => ({
  src: props.src || '',
  height: props.height || 'auto',
}))`
  background-color: white;
  border-radius: 36px;
  box-sizing: content-box;
  display: inline-block;
  vertical-align: middle;
  transform: scale(0.76);
  ${media.minWidth.mobile`
    transform: scale(1);
  `}
  &.redbubble {
    margin: 0 0 2px -18px;
    padding: 10px 32px 11px 12px;
    ${media.minWidth.mobile`
      margin: 2px 0 12px 0;
    `}
    ${media.minWidth.tablet`
      margin: 0 0 8px 0;
    `}
  }
  &.bandcamp {
    margin: 0 0 2px -22px;
    padding: 18px 23px 11px 21px;
    ${media.minWidth.mobile`
      margin: 2px 0 12px 0;
    `}
    ${media.minWidth.tablet`
      margin: 0 0 8px 0;
    `}
  }
`
