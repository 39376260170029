import styled, { css } from 'styled-components'

import animate from '@keyframes'
import CBTPgraysAss from '@raster/tv-color-test-pattern-grayscale.gif'
import FWWMnoiseAss from '@raster/fwwm-noise.gif'

export const BannerBlockOuter = styled.div`
  background-size: cover;
  display: block;
  height: 18.4615385vw;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100vw;
`
export const BannerBlockInner = styled(BannerBlockOuter)`
  margin: 0 4.6153845vw;
  perspective: 23em;
  width: 90.769231vw;
  .video-js,
  .video-js video {
    position: absolute;
    top: 0;
    left: 0;
    width: 91.5vw !important;
    max-width: 100vw !important;
  }
`
export const BannerScaler = styled(BannerBlockInner)``

/* OG BANNER HAD NO STYLE(S)!! */
export const BannerBlockAnchor = styled(BannerBlockOuter).attrs(props => ({
  href: props.href || '',
  target: props.target || '_self',
  title: props.title || '',
}))`
  position: relative;
  background-image: url('${FWWMnoiseAss}');
`

export const AnimeElement = styled.div`
  backface-visibility: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: contain;
  box-sizing: border-box;
  display: block;
  height: inherit;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  transform-style: preserve-3d;
  width: inherit;
`

const colorBarRight = css`
  transform: scale(-1);
  left: calc(95.3846154vw - 1px);
  .grays {
    transform: scaleX(-1);
  }
`
export const ColorBarsTestPatternWrapper = styled(AnimeElement)`
  height: 18.4615385vw;
  left: 0;
  overflow: hidden;
  top: 0;
  width: calc(4.61538462vw + 1px);
  z-index: 10;
  ${props => props.reflect ? colorBarRight : ''}
  body#test & {
    opacity: 0;
  }
`
export const ColorBar = styled(AnimeElement)`
  height: 36.9230769vw;
  &.colors {
    left: 0;
    top: 0;
    width: calc(2.15384615vw + 1px);
    ${animate.translateInUnits({ duration: 4.2, iterations: 'infinite', units: 'vw', tTo: -12.3076923 })}
    div {
      position: relative;
      width: 100%;
      height: 12.3076923vw;
      background: linear-gradient(180deg,
        #7f7f7f 0%, #7f7f7f 14.27%,
        #fefe00 14.28%, #fefe00 28.56%,
        #00fefe 28.57%, #00fefe 42.84%,
        #00fe00 42.85%, #00fe00 57.13%,
        #fe00fe 57.14%, #fe00fe 71.41%,
        #fe0000 71.42%, #fe0000 85.70%,
        #0000fe 85.71%, #0000fe 100%
        );
    }
  }
  &.comps {
    left: 2.15384615vw;
    top: 0;
    width: calc(0.71794872vw + 1px);
    ${animate.translateInUnits({ duration: 4, iterations: 'infinite', units: 'vw', tFrom: -12.3076923, tTo: 0 })}
    div {
      position: relative;
      width: 100%;
      height: 12.3076923vw;
      background: linear-gradient(180deg,
        #0000fe 0%, #0000fe 14.27%,
        #000 14.28%, #000 28.56%,
        #fe00fe 28.57%, #fe00fe 42.84%,
        #000 42.85%, #000 57.13%,
        #00fefe 57.14%, #00fefe 71.41%,
        #000 71.42%, #000 85.70%,
        #fefefe 85.71%, #fefefe 100%
        );
    }
  }
  &.grays {
    ${animate.translateInUnits({ duration: 10, iterations: 'infinite', units: 'vw', tTo: -12.3076923 })}
    left: 2.87179487vw;
    top: 0;
    width: calc(1.74358974vw + 1px);
    background-image: url('${CBTPgraysAss}');
    background-size: calc(1.74358974vw + 2px) 12.3076923vw;
    background-position: -1px 0;
    background-repeat: repeat-y;
  }
`
