import styled from 'styled-components'

export const VidDiv = styled.div.attrs(props => ({
  className: props.className || ``,
}))`
  top: 0;
  .vjs-text-track-display,
  .vjs-loading-spinner,
  .vjs-big-play-button,
  .vjs-modal-dialog {
    display: none;
  }
  .screen {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
  }
`
