import styled from 'styled-components'

import { media, setRgba } from '@styles'

import EarRows from '@vector/earrows.svg'
// import EarRowsURL, { ReactComponent as EarRows } from '@vector/earrows.svg'

export const WWidgetColumnsWrapper = styled.div`
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
  max-width: 976px;
  margin: 0 auto;
  ${media.minWidth.desktop`
    flex-wrap: nowrap;
    margin-top: -14px;
  `}
`

export const WWidgetColumn = styled.div.attrs(props => ({
  className: props.className || '',
}))`
  &:first-child,
  &:last-child {
    align-content: stretch;
    align-items: center;
    background-image: url('${EarRows}');
    background-repeat: repeat-y;
    background-size: 300px auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 336px;
  }
  &:first-child {
    background-position: calc(50% - 64px) 2px;
    top: -88px;
    ${media.minWidth.mobile`
      top: -48px;
    `}
    ${media.minWidth.tablet`
      top: -32px;
    `}
    ${media.minWidth.desktop`
      top: 0;
    `}
  }
  &:last-child {
    background-position: calc(50% + 9px) 2px;
    top: -386px;
    ${media.minWidth.mobile`
      top: -100px;
    `}
    ${media.minWidth.desktop`
      top: 0;
    `}
  }
  & > * {
    align-self: auto;
    flex: 0 1 auto;
    margin: 0;
    width: 320px;
    order: 0;
    padding: 0;
    position: relative;
  }
  align-self: auto;
  flex: 0 1 auto;
  order: 0;
  top: -200px;
  transform: scale(0.88);
  ${media.minWidth.mobile`
    margin-top: 23px;
    top: -42px;
    transform: scale(1);
    width: 88%;
  `}
  ${media.minWidth.tablet`
    top: -32px;
  `}
  ${media.minWidth.desktop`
    top: 0;
  `}
`

export const WWidgetWrapper = styled.div`
  display: inline-block;
  &:first-child,
  &:last-child {
    margin: 18px 18px 0;
  }
`

export const WWidget = styled.div`
  &#spotify-wwidget {
    background-color: black;
    margin-top: -4px;
    padding-bottom: 1px;
  }
  &#soundcloud-wwidget {
    background-color: ${setRgba('soundCloudOrangeDark')};
    margin-top: -4px;
  }
  &#redbubble-wwidget,
  &#redbubble-wwidget iframe {
    width: 100vw;
    margin: -16px auto;
    ${media.minWidth.mobile`
      margin: -8px auto 0;
    `}
    ${media.minWidth.tablet`
      margin: 18px auto;
      width: 88%;
    `}
  }
`

export const WWidgetHeader = styled.img`
`

export const WWidgetc = styled.div`
  top: -442px;
  ${media.minWidth.mobile`
    top: -100px;
  `}
  ${media.minWidth.desktop`
    top: 0;
  `}
  & > iframe {
    margin: 42px auto 0;
    transform-origin: 0, 0;
    transform: scale(1.42, 1.42);
    height: 50px;
    width: 185px;
    border: 0;
  }
`

export const VectEar = styled.img`
  display: block;
  margin: 42px 42px 216px;
  height: 254px;
  &.ear-left {
    transform: scaleX(-1);
  }
`
