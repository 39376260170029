import React, { useEffect, useState } from 'react'
import { useWindowSize } from '@react-hook/window-size/throttled'

import { WWidget } from './styled'

const Redbubble = ({ showProducts = 8 }) => {
  // CHANGE WIDGET GRID CONFIG DEPENDING ON CURRENT WINDOW WIDTH
  //
  const [
    ww,
    wh,
  ] = useWindowSize({ fps: 24 })
  const [
    iFrameStyle,
    setIFrameStyle,
  ] = useState({
    height: 0,
    width: 0,
  })
  useEffect(() => {
    let ifst = {
      rows: 0,
      cols: 0,
    }
    if (ww >= 1024) {
      ifst.rows = 2
      ifst.cols = 4
    } else if (ww >= 600) {
      ifst.rows = 4
      ifst.cols = 2
    } else {
      ifst.rows = 8
      ifst.cols = 1
    }
    setIFrameStyle({
      height: 222 * ifst.rows + 85,
      width: 240 * ifst.cols + 26,
    })
  }, [ww])
  //

  return (
    <WWidget id='redbubble-wwidget'>
      <iframe src={ `https://www.redbubble.com/people/billyzduke/external-portfolio?count=${showProducts}` }
        frameBorder='0'
        scrolling='no'
        allowtransparency='true'
        style={ iFrameStyle }
      />
    </WWidget>
  )
}

export default Redbubble
