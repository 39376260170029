import React, { useContext, useEffect, useRef, useState } from 'react'
import videojs from 'video.js'
// import 'video.js/dist/video-js.min.css'

import { VidDiv } from './styled'
import WindowContext from '@context/window'

const Video = ({ callBack, className, id, fill, screens, src, srcsForMinWidths, style, ...etcProps }) => {
  const w = useContext(WindowContext)
  const vRef = useRef(null)
  const [ objectFitStyle, setObjectFitStyle ] = useState({})

  useEffect(() => {
    if (fill) {
      const fitStyle = {
        minWidth: style.minWidth || '100vw',
      }
      if (fill === 'preserve') {
        fitStyle.objectFit = 'cover'
        fitStyle.minHeight = style.minHeight || '100vh'
      } else {
        fitStyle.objectFit = 'fill'
        fitStyle.maxHeight = style.maxHeight || '100vh'
        fitStyle.position = 'absolute'
        fitStyle.left = 0
      }
      setObjectFitStyle(fitStyle)
    }

    if (vRef) {
      let useSrc = src
      if (srcsForMinWidths) {
        srcsForMinWidths.forEach(s => {
          if (w.w > s.minWidth) useSrc = s.src
        })
      }

      const player = (videojs(vRef.current, { ...etcProps }, () => {
        if (useSrc) player.src(useSrc)
        if (callBack) callBack(player)
      }))
    }
    return () => {
      if (typeof player === 'object') {
        player.dispose()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ w ]) // RUN ON LOAD AND WHEN VARS UPDATE

  return (
    <VidDiv id={ id }
      className={ className }
      style={ style }
    >
      <video ref={ vRef }
        className='video-js'
        style={ objectFitStyle }
      />
      {
        screens
          ? screens.map((screen, i) => (
            <div key={ i }
              id={ `${id}-screen-${(i + 1)}` }
              className='screen'
              style={ {
                backgroundColor: screen.color,
                mixBlendMode: screen.blend,
              } }
            />
          ))
          : ''
      }
    </VidDiv>
  )
}

export default Video
