import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SanityImage from 'gatsby-plugin-sanity-image'
import { Zoom as SlideShowZoomTrans } from 'react-slideshow-image'
import classNames from 'classnames'

import { FragmentSanityTree, FragmentSanityTreeItemImageWithPreview } from '@fragments'
import SlideShowArrow from './arrow'
import SlideShowIndicator from './indicator'
import { SlideShowWrapper } from './styled'

const SlideShow = () => {
  const [ reversed, setReversed ] = useState(false)

  const slideShowTreeQuery = graphql`
    query slideShowTreeQuery {
      slideTree: sanityTree(slug: { current: {eq: "splash-slideshow"}}) {
        ...SanityTreeOwnFields
        items {
          ...SanityTreeItemImageWithPreview
        }
      }
    }
  `

  const {
    slideTree: { items: ssImages },
  } = useStaticQuery(slideShowTreeQuery)

  useEffect(() => {
    if (!reversed) {
      ssImages.reverse()
      setReversed(true)
    }
  }, [ reversed, ssImages ]) // RUN ON LOAD AND WHEN VARS UPDATE

  const slideShowPluginProps = {
    duration: 4200,
    transitionDuration: 420,
    indicators: true,
    scale: 2,
    pauseOnHover: true,
    canSwipe: true,
    easing: 'cubic-in',
    prevArrow: <SlideShowArrow invert />,
    nextArrow: <SlideShowArrow />,
    indicators: i => (<SlideShowIndicator title={ ssImages[i].title } />),
  }

  return (
    <>
      <SlideShowWrapper className={ classNames({
        ssReady: reversed,
        clearUnfix: true,
      }) }
      >
        <SlideShowZoomTrans { ...slideShowPluginProps }>
          { ssImages.map(({ title, image }, k) => (
            <div key={ k }
              style={ { width: '100%' } }
            >
              <SanityImage style={ { objectFit: 'cover', width: '100%' } }
                alt={ title }
                { ...image }
              />
            </div>
          )) }
        </SlideShowZoomTrans>
      </SlideShowWrapper>
    </>
  )
}

export default SlideShow
