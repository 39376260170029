import React from 'react'

import { WWidgetWrapper, WWidgetHeader, WWidget } from './styled'
import SpotifyWWidgetHeader from '@vector/spotify-logo.svg'

const Spotify = () => {
  return (
    <WWidgetWrapper>
      <WWidgetHeader src={ SpotifyWWidgetHeader } />
      <WWidget id='spotify-wwidget'>
        <iframe src='https://embed.spotify.com/?uri=spotify%3Aalbum%3A7xpQ84Z3VFWNgueDJTVa8g'
          width='300'
          height='380'
          frameBorder='0'
          allowtransparency='true'
        />
      </WWidget>
    </WWidgetWrapper>
  )
}

export default Spotify
