import React from 'react'

import { WWidgetWrapper, WWidgetHeader, WWidget } from './styled'
import SoundCloudWWidgetHeader from '@vector/soundcloud-logo.svg'

const SoundCloud = () => {
  return (
    <WWidgetWrapper>
      <WWidgetHeader src={ SoundCloudWWidgetHeader } />
      <WWidget id='soundcloud-wwidget'>
        <iframe width='100%'
          height='450'
          scrolling='no'
          frameBorder='no'
          src='https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/99768155&amp;auto_play=false&amp;hide_related=false&amp;show_comments=true&amp;show_user=true&amp;show_reposts=false&amp;visual=true'
        />
      </WWidget>
    </WWidgetWrapper>
  )
}

export default SoundCloud
