import React from 'react'
import { isMobile } from 'react-device-detect'

import Video from '@components/html/video'
import { BannerBlockInner, BannerBlockAnchor, BannerBlockOuter } from './styled'
import WWHR from '@components/html/ww-hr'
import ColorBarsTestPattern from './cbtp'
import OYOYAss30fps480 from '@video/OYOY-800x162-30fps.mp4'
import OYOYAss30fps800 from '@video/OYOY-1024x208-30fps.mp4'
import OYOYAss30fps1024 from '@video/OYOY-1280x260-30fps.mp4'
import OYOYAss30fps1280 from '@video/OYOY-1680x340-30fps.mp4'
import OYOYAss30fps1680 from '@video/OYOY-1920x390-30fps.mp4'
import OYOYAss30fps1920 from '@video/OYOY-2160x438-30fps.mp4'
import OYOYAss30fps2160 from '@video/OYOY-3716x752-30fps.mp4'
import OYOYAss60fps480 from '@video/OYOY-800x162-60fps.mp4'
import OYOYAss60fps800 from '@video/OYOY-1024x208-60fps.mp4'
import OYOYAss60fps1024 from '@video/OYOY-1280x260-60fps.mp4'
import OYOYAss60fps1280 from '@video/OYOY-1680x340-60fps.mp4'
import OYOYAss60fps1680 from '@video/OYOY-1920x390-60fps.mp4'
import OYOYAss60fps1920 from '@video/OYOY-2160x438-60fps.mp4'
import OYOYAss60fps2160 from '@video/OYOY-3716x752-60fps.mp4'
// src={ OYOYAss } // single source, proven to work

const BannerVideo = ({ id }) => {
  const srcsForDeviceAndMinWidths = [
    {
      src: isMobile ? OYOYAss30fps480 : OYOYAss60fps480,
      minWidth: 0,
    },
    {
      src: isMobile ? OYOYAss30fps800 : OYOYAss60fps800,
      minWidth: 800,
    },
    {
      src: isMobile ? OYOYAss30fps1024 : OYOYAss60fps1024,
      minWidth: 1024,
    },
    {
      src: isMobile ? OYOYAss30fps1280 : OYOYAss60fps1280,
      minWidth: 1280,
    },
    {
      src: isMobile ? OYOYAss30fps1680 : OYOYAss60fps1680,
      minWidth: 1680,
    },
    {
      src: isMobile ? OYOYAss30fps1920 : OYOYAss60fps1920,
      minWidth: 1920,
    },
    {
      src: isMobile ? OYOYAss30fps2160 : OYOYAss60fps2160,
      minWidth: 2160,
    },
  ]

  return (
    <div id={ id }>
      <WWHR className='pre-bc' />
      <BannerBlockAnchor as="a"
        href='https://wrongwindows.bandcamp.com/album/days-of-yore'
        target='_blank'
        title='Own YOUR Own YORE Today!'
      >
        <BannerBlockOuter>
          <BannerBlockInner>
            { typeof window !== 'undefined'
              ? <Video id='banner_video'
                srcsForMinWidths={ srcsForDeviceAndMinWidths }
                style={ {
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '90.769231vw',
                  height: '18.4615385vw',
                  minWidth: 'auto',
                  minHeight: 'auto',
                  maxWidth: '90.769231vw',
                  maxHeight: '18.4615385vw',
                } }
                width='100%'
                controls={ false }
                controlBar={ false }
                fill='preserve'
                autoplay
                muted
                loop
                playsinline
                preload='auto'
              />
              : ''
            }
          </BannerBlockInner>
          <ColorBarsTestPattern />
          <ColorBarsTestPattern reflect />
        </BannerBlockOuter>
      </BannerBlockAnchor>
      <WWHR className='post-bc'>
        <a name='listen' />
      </WWHR>
    </div>
  )
}

export default BannerVideo
