import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// import WindowContext from '@context/window'
import { FragmentSanityTree, FragmentSanityTreeItem } from '@fragments'
import GLink from '@components/html/g-link'
import {
  BandcampTextLinkBlock,
  BandNameBlock,
  HypeAndSocialWrapper,
  HypeAndSocialBlock,
  SocialLinksBlock,
} from './styled'
import { BrIfTablet } from '@styles'

const sociaLinksQuery = graphql`
  query SociaLinksQuery {
    sociaLinks: sanityTree(slug: { current: {eq: "outlinks-social-media"}}) {
      ...SanityTreeOwnFields
      items {
        ...SanityTreeItem
      }
    }
  }
`

const HypeAndSocial = () => {
  const { sociaLinks: { items } } = useStaticQuery(sociaLinksQuery)

  // console.log(items)

  const listAnchor = item => {
    const { active, id, image: imageDefault, imageActive, title: site, reference: { title, href } } = item
    return active
      ? (
        <GLink key={ id }
          className={ `
          sociaLink-icon sociaLink-${site.toLowerCase()}
        ` }
          href={ href }
          target='_blank'
          aria-label={ title }
          title={ title }
        >
          <img src={ imageDefault.asset.url }
            aria-hidden
          />
          <img src={ imageActive.asset.url }
            className='O'
            aria-hidden
          />
        </GLink>
      )
      : ('')
  }

  const listItems = items => items.map(item => listAnchor(item))

  return (
    <HypeAndSocialWrapper>
      <BandNameBlock>TH3 WR0NG W!ND0W5</BandNameBlock>
      <HypeAndSocialBlock>
        <BandcampTextLinkBlock>
          <a href='https://bit.ly/wwdaysofyore'
            target='_blank'
          >Debut album DAYS OF YORE <BrIfTablet />Deluxe Limited Edition <BrIfTablet />Vinyl Available Now!</a>
        </BandcampTextLinkBlock>
        <SocialLinksBlock className='clearUnfix'>
          { listItems(items) }
        </SocialLinksBlock>
      </HypeAndSocialBlock>
    </HypeAndSocialWrapper>
  )
}

export default HypeAndSocial
