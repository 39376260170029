import React from 'react'

import { ColorBarsTestPatternWrapper, ColorBar } from './styled'

const ColorBarsTestPattern = (props) => {
  return (
    <ColorBarsTestPatternWrapper { ...props }>
      <ColorBar className='colors'>
        <div /><div /><div />
      </ColorBar>
      <ColorBar className='comps'>
        <div /><div /><div />
      </ColorBar>
      <ColorBar className='grays' />
    </ColorBarsTestPatternWrapper>
  )
}

export default ColorBarsTestPattern
